.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #FFF;
}
a:hover {
   color: #e7ebee
}

* {
  font-family: 'Barlow', sans-serif; 
}

.warningLabel {
  color: red;
  font-style: italic;
}

#sidebar {
  position: fixed;
}

#sendButton {
  background-color: #135393;
  color: white;
  border-color: white;
}

#sendButton:active {
  background-color: #1a7cdd;
  color: white;
  border: white;
}